import React, { useState } from "react";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import useSwipe from "../../util/useSwipe";

export default function ChapterReader({ pages, current_page }) {
  const [activeTab, setActiveTab] = useState(current_page);
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      const nextPage = Math.min(activeTab + 1, pages.length - 1);
      if (nextPage == pages.length - 1) {
        callNextChapter()
      }
      document.querySelector(`ul[role="tablist"] li[data-value="${pages[nextPage]._id}"]`)?.click()
      setActiveTab(nextPage)
    },
    onSwipedRight: () => {
      const nextPage = Math.max(activeTab - 1, 0);
      if (nextPage == 0) {
        callPreviousChapter()
      }
      document.querySelector(`ul[role="tablist"] li[data-value="${pages[nextPage]._id}"]`)?.click()
      setActiveTab(nextPage)
    }
  });

  const callNextChapter = () => {
    if (window.Unity) {
      Unity.call("nextChapter");
    }
    console.log("next chapter")
  }

  const callPreviousChapter = () => {
    if (window.Unity) {
      Unity.call("previousChapter");
    }
    console.log("previous chapter")
  }

  return (
    <Tabs value={pages[activeTab]._id}>
      <TabsBody

        animate={{
          initial: { x: 250 },
          mount: { x: 0 },
          unmount: { x: 250 },
        }}>
        {pages.map(({ _id, content }) => (
          <TabPanel key={_id} value={_id}>
            <div {...swipeHandlers} dangerouslySetInnerHTML={{ __html: content }} />
          </TabPanel>
        ))}
      </TabsBody>
      <TabsHeader
        className="bg-transparent justify-center z-20"
        indicatorProps={{
          className: "bg-gray-900/10 shadow-none !text-gray-900",
        }}
      >
        {pages.map(({ _id }, index) => (
          <Tab className={index == activeTab ? "w-auto px-2 py-1" : "w-auto px-2 py-1 hidden"} key={_id} value={_id} onClick={() => setActiveTab(index)}
          >
            {index + 1}
          </Tab>
        ))}
      </TabsHeader>

    </Tabs>
  );
}