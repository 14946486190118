import { define } from 'remount'
import Login from './pages/Login'
import Chapters from './pages/Chapters'
import ChapterForm from './pages/ChapterForm'
import Pages from './pages/Pages'
import PageForm from './pages/PageForm'
import ChapterReader from './pages/ChapterReader'


// Chapters
define({ 'login-component': Login })
define({ 'chapters-component': Chapters })
define({ 'new-chapter-component': ChapterForm })
define({ 'chapter-reader': ChapterReader })

// Pages

define({ 'pages-component': Pages })
define({ 'new-page-component': PageForm })
